/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import Button, { VARIANT } from '@components/Button/Button.js';
import style from './ThankuSignupPage.module.scss';
const ThankuSignupPage = ({ data }) => {
    // console.log('data', data);

    const { title, thank_you_button_text, thank_you_button_link, card_caption } = data;

    return (
        <div className={style.ThankuSignupPage}>
            <section className={style.thankuContainer}>
                <div className={""}>
                    <div className={style.titleThankuSignup}>
                        <RichText render={title.richText} />
                    </div>
                    <div className={style.titleThankuButton}>
                        <Button
                            variant={VARIANT.PRIMARY}
                            to={thank_you_button_link?.url}
                        >
                            <RichText render={thank_you_button_text?.richText} />
                        </Button>
                    </div>
                    <div className={style.thankuCardCaption}>
                        <RichText render={card_caption?.richText} />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ThankuSignupPage;

ThankuSignupPage.propTypes = {
    data: PropTypes.object.isRequired,
};
