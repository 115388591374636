import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '@components/Layout';
import ThankuSignupPage from '../scenes/ThankuSignupPage';

const Page = ({ data }) => {

  // console.log(data, '-data  of thankuSignPage');
  const thankuPageContent = data?.allPrismicThankYouSignUp?.edges[0];
  if (!thankuPageContent) return null;
  const onThankUPage = thankuPageContent?.node;
  const {
    uid,
    id,
    lang,
    type,
    alternate_languages,
    data: pageData,
  } = onThankUPage;

  const { metatitle, metadescription, canonical } = pageData;
  const activeDocMeta = { id, uid, lang, type, alternate_languages };
  // console.log('Thank-u-page', pageData);
  // console.log('activeDocMeta into Thank-u-page-data', activeDocMeta);

  return (
    <Layout
      activeDocMeta={activeDocMeta}
      metatitle={metatitle}
      metadescription={metadescription}
      canonical={canonical}
    >
      <ThankuSignupPage data={pageData} metatitle={metatitle} canonical={canonical} />
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    allPrismicThankYouSignUp {
      edges {
        node {
          uid
          id
          type
          lang
          url
          alternate_languages {
            id
            lang
            type
            uid
          }
          data {
            metatitle {
              text
            }
            metadescription {
              text
            }
            canonical {
              text
            }
            title {
              richText
            }
            thank_you_button_text {
              richText
            }
            thank_you_button_link {
              url
            }
            card_caption {
              richText
            }
          }
        }
      }
    }
  }
`;



export default Page;
